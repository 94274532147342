import type { Dataset, DatasetVersion } from "~/types"

export interface SourceApiResponse {
    id: string,
    name: string,
    SourceFolderId: string | null,
    updatedAt: string,
    createdAt: string,
    source: SourceDto
    version: SourceVersionDto
    details: WebsiteSourceDetails | DatasetSourceDetails | OutputTableDetails
}

export enum SourceType {
    WEBPAGE = 'WEBPAGE',
    DATASET = 'DATASET',
    OUTPUT_TABLE = 'OUTPUT_TABLE',
    PDF = 'PDF',
}

export interface SourceDto {
    id: string
    title: string
    name: string
    type: SourceType
    createdAt: string,
    updateAvailable: boolean,
    updatedAt: string,
    description: string,
    SourceFolderId: string | null,
    currentVersionId: string,
}


export enum SourceVersionUpdateType {
    INITIAL_VERSION = 'INITIAL_VERSION',
    AUTO_UPDATE = 'AUTO_UPDATE',
    MANUAL_UPDATE = 'MANUAL_UPDATE',
}

export interface SourceVersionDto {
    id: string;
    createdAt: string;
    updateType: SourceVersionUpdateType;
    updatedAt: string;
    sourceId: string;
    status: string;
    updateSuggestion: string | null
}

export interface WebsiteSourceDetails {
    id: string;
    azureVectorFileId: string;
    status: 'COMPLETED' | 'PENDING' | 'FAILED';
    title: string;
    url: string;
    description: string;
    tags: string[];
    interpretedType: string;
    image: string;
    createdAt: string;
    vectorStoreId: string;
    sourceVersionId: string;
}

export interface OutputTableDetails {
    code: string;
    createdAt: string;
    resultTable: string;
    name: any;
    updatedAt: string;
    sourceVersionId: string;
    dependencyUpdateMethod: string;
    filterModel: any | null;
    sortModel: Array<any> | null;
}

export interface DatasetSourceDetails {
    dataset: Dataset,
    version: DatasetVersion,
}

export interface SourceTag {
    id: string;
    value: string;
    createdAt: string;
    systemAssigned: boolean;
    userId: string;

    sourceId: string;
}

export interface OutputTableDependancy {
    dfName: string;
    name: string;
    sourceId: string;
    link: string;
}

export interface SourcePermission {
    id: string;
    sourceId: string;
    userId: string;
    grantedByUserId: string | null;
    allowRead: boolean;
    allowWrite: boolean;
    allowManage: boolean;
    allowShare: boolean;
    isOwner: boolean;
    createdAt: string;
    updatedAt: string;
}

export interface UpdateTableResponseDto {
    type: 'QUESTION' | 'UPDATED' | 'NOT_UPDATED',
    message: string,
    possibleAnswer?: string,
}