import axios from 'axios';
import type { Column, HomeSearchResult } from '~/types';
import { SourceVersionUpdateType, type SourceDto, type SourceVersionDto, type WebsiteSourceDetails, SourceType, type SourceApiResponse, type SourceTag, type OutputTableDependancy, type SourcePermission, type UpdateTableResponseDto } from './types/knowledgeService';

export function useKnowledgeService() {
    const config = useRuntimeConfig();
    const { toggleDialog } = useAppStore();
    const api = axios.create({
        baseURL: `${config.public.baseUrl}/api/datasets/sources`,
        // baseURL: `http://localhost:3000`,
        headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'max-age=3600',
            ...useRequestHeaders(['cookie']),
        }
    })

    api.interceptors.response.use(
        response => response,
        error => {
            if (error.response && error.response.status === 402) {
                console.log('payment required');
                toggleDialog('upgrade', true, error.response.data.message);
                // Return a rejected promise to propagate the error
                return Promise.reject(error);
            }
            return Promise.reject(error);
        }
    );


    /**
    * Get a nested list of all the knowledge sources that the user has access to.
    *
    * @async
    * @function listKnowledgeSources
    * @param searchParams - Optional search parameters to filter the list of sources. (eg: { type: 'webpage' } or { tags: 'tag1,tag2' })
    * @returns Resolves with a list of all the tags currently in use by the user.
    */
    async function listKnowledgeSources(searchParams?: { [key: string]: string }) {
        const { data } = await api.get('', {
            params: searchParams || {}
        });

        return data;
    }


    async function listKnowledgeSourcesFlat() {
        const { data } = await api.get<SourceApiResponse[]>('?flat=true');
        return data;
    }

    async function createWebPage(url: string, target: 'user' | 'organization') {
        const { data: createdSource } = await api.post<SourceDto>('/', {
            type: SourceType.WEBPAGE,
        })
        const { data: createdVersion } = await api.post<SourceVersionDto>(`/${createdSource.id}/versions`, {
            updateType: SourceVersionUpdateType.INITIAL_VERSION,
        })
        const { data: webpage } = await api.post<WebsiteSourceDetails>(`/${createdSource.id}/versions/${createdVersion.id}/webpages`, { url, target, });
        return createdSource;
    }

    /**
    * Creates a new PDF source and uploads the file, optionally waiting for processing to complete.
    *
    * @async
    * @function createPdf
    * @param {File} file - The PDF file to upload and process.
    * @param {boolean} [wait=false] - Whether to wait for the PDF processing to complete before returning.
    * @returns {Promise<Object>} A promise that resolves with the created source or with the completed source details if `wait` is true.
    * 
    * @example
    * // Create a PDF source and process it asynchronously
    * const pdfSource = await createPdf(myFile);
    *
    * @example
    * // Create a PDF source and wait for processing to complete
    * const completedPdfSource = await createPdf(myFile, true);
    */
    async function createPdf(file: File, wait: boolean = false): Promise<SourceDto> {
        const createdSource = await createSource({
            type: SourceType.PDF,
            title: file.name,
        })

        const createdSourceVersion = await createSourceVersion(createdSource.id, {
            updateType: 'INITIAL_VERSION',
        })
        const contentUrl = await getSourceVersionContentUrl(createdSource.id, createdSourceVersion.id);
        const { uploadFile } = useDataService();
        await uploadFile(contentUrl, file, (progress) => {
            console.log(progress)
        })
        await processPdf(createdSource.id, createdSourceVersion.id)

        if (!wait) {
            return createdSource;
        }

        let details = await getKnowledgeSource(createdSource.id)
        while ((details.details as any).status !== 'COMPLETED') {
            await new Promise((resolve) => setTimeout(resolve, 1000))
            details = await getKnowledgeSource(createdSource.id)

            // if status is error, throw an error
            if ((details.details as any).status === 'ERROR') {
                throw new Error('PDF processing failed');
            }
        }

        return createdSource;
    }


    /**
    * Get a knowledge source by id.
    *
    * @async
    * @function getKnowledgeSource
    * @param id - The id of the knowledge source to retrieve.
    * @returns Resolves with the knowledge source.
    */
    async function getKnowledgeSource(id: string) {
        const { data } = await api.get<SourceApiResponse>(`/${id}`);

        return data;
    }

    async function getKnowledgeSourceVersionDetails<T>(sourceId: string, versionId: string) {
        const { data } = await api.get<T>(`/${sourceId}/versions/${versionId}/details`);
        return data
    }

    async function searchKnowledgeSources(query: string) {
        const { data } = await api.get<HomeSearchResult[]>(`/search?q=${query}`);
        return data;
    }

    async function createFolder(folder: any) {
        const { data } = await api.post('/folders', folder);
        return data;
    }

    async function deleteFolder(folderId: string) {
        await api.delete(`/folders/${folderId}`);
    }

    async function updateFolder(folderId: string, folder: any) {
        const { data } = await api.patch(`/folders/${folderId}`, folder);
        return data;
    }

    async function moveFolder(folderId: string, newParentFolderId?: string | null) {
        const { data } = await api.patch(`/folders/${folderId}`, { SourceFolderId: newParentFolderId });
        return data;
    }

    async function moveSource(sourceId: string, targetFolderId: string) {
        const { data } = await api.patch(`/${sourceId}`, { SourceFolderId: targetFolderId });
        return data;
    }

    async function deleteSource(sourceId: string) {
        await api.delete(`/${sourceId}`);
    }

    async function updateSource(sourceId: string, source: any) {
        const { data } = await api.patch(`/${sourceId}`, source);
        return data;
    }

    // New methods for source versions
    async function listSourceVersions(sourceId: string) {
        const { data } = await api.get<any[]>(`/${sourceId}/versions`);
        return data;
    }

    async function createSourceVersion(sourceId: string, version: any) {
        const { data } = await api.post(`/${sourceId}/versions`, version);
        return data;
    }

    async function getSourceVersion(sourceId: string, versionId: string) {
        const { data } = await api.get(`/${sourceId}/versions/${versionId}`);
        return data;
    }

    async function createSource(props: Partial<SourceDto>) {
        const { data } = await api.post<SourceDto>('/', props);
        return data
    }

    async function updateSourceVersion(sourceId: string, versionId: string, version: any) {
        const { data } = await api.patch(`/${sourceId}/versions/${versionId}`, version);
        return data;
    }

    async function deleteSourceVersion(sourceId: string, versionId: string) {
        await api.delete(`/${sourceId}/versions/${versionId}`);
    }

    async function getSourcePermissions(sourceId: string) {
        const { data } = await api.get<SourcePermission[]>(`/${sourceId}/permissions`);
        return data;
    }

    async function createSourcePermission(sourceId: string, props: any) {
        const { data } = await api.post<{ id: string }>(`/${sourceId}/permissions`, props);
        return data;
    }

    async function deleteSourcePermission(sourceId: string, permissionId: string) {
        const { data } = await api.delete(`/${sourceId}/permissions/${permissionId}`);
        return data;
    }

    async function getOutputTableAnalysisBreakdown(sourceId: string, versionId: string) {
        const { data } = await api.get<{ code: string, breakdown: string }>(`/${sourceId}/versions/${versionId}/output-tables/analysis-breakdown`);
        return data;
    }

    async function listSourceTags(sourceId: string) {
        const { data } = await api.get<SourceTag[]>(`/${sourceId}/tags`);
        return data;
    }

    async function createSourceTag(sourceId: string, tag: Partial<SourceTag>) {
        const { data } = await api.post<SourceTag>(`/${sourceId}/tags`, tag);
        return data;
    }

    async function deleteSourceTag(sourceId: string, tagId: string) {
        await api.delete(`/${sourceId}/tags/${tagId}`);
    }

    async function getOutputTableDataUrl(sourceId: string, versionId: string,) {
        const { data } = await axios.get<{ data: string }>(`${config.public.baseUrl}/api/datasets/sources/${sourceId}/versions/${versionId}/output-tables/data`)
        return data.data;
    }

    async function getOutputTableSampleData(sourceId: string, versionId: string,) {
        const { data } = await axios.get<Array<any>>(`${config.public.baseUrl}/api/datasets/sources/${sourceId}/versions/${versionId}/output-tables/sample`)
        return data;
    }

    async function getOutputTableDependancies(sourceId: string, versionId: string,) {
        const { data } = await axios.get<Array<OutputTableDependancy>>(`${config.public.baseUrl}/api/datasets/sources/${sourceId}/versions/${versionId}/output-tables/dependencies`)
        return data;
    }

    async function getSourceVersionContentUrl(sourceId: string, versionId: string) {
        const { data } = await axios.get<{ url: string }>(`${config.public.baseUrl}/api/datasets/sources/${sourceId}/versions/${versionId}/content`)
        return data.url;
    }

    async function processPdf(sourceId: string, versionId: string) {
        await api.post(`/${sourceId}/versions/${versionId}/pdf/process`);
    }

    async function createPlainOutputTable(sourceId: string, versionId: string, props: any) {
        const { data } = await api.post(`/${sourceId}/versions/${versionId}/output-tables/`, props, {
            params: {
                plain: 'true'
            }
        });
        return data;
    }

    async function createOutputTableDependancy(sourceId: string, versionId: string, props: { dfName: string | null, sourceVersionId: string }) {
        const { data } = await api.post(`/${sourceId}/versions/${versionId}/output-tables/dependencies`, props);
        return data;
    }

    async function updateKnowledgeSourceVersionColumns(sourceId: string, versionId: string, column: Partial<Column>[]) {
        const { data } = await axios.patch(`${config.public.baseUrl}/api/datasets/sources/${sourceId}/versions/${versionId}/columns/`, column);
        return data;
    }


    async function deleteKnowledgeSourceVersionColumn(sourceId: string, versionId: string, columnId: string) {
        await axios.delete(`${config.public.baseUrl}/api/datasets/sources/${sourceId}/versions/${versionId}/columns/${columnId}`);
    }

    /**
     * Get a list of all the tags currently in use by the user
     *
     * @async
     * @function listTags
     * @returns Resolves with a list of all the tags currently in use by the user.
     */
    async function listTags() {
        const { data } = await api.get<string[]>('/tags');
        return data
    }

    /**
     * Saves a modified dataset as a new source with specified attributes.
     *
     * @async
     * @function saveModifiedDatasetAsSource
     * @param name - The name of the source to create (e.g. "dataset name (filtered)").
     * @param description - A brief description of the dataset.
     * @param options - Table options from AG-Grid.
     * @param dataUrl - The URL containing orignal the data to be saved.
     * @param sourceVersionId - The sourceVersionId from the datasetVersion to be saved (as a dep)
     * @returns Resolves when the dataset is successfully saved as a source.
     */
    async function saveModifiedDatasetAsSource(name: string, description: string, options: any, dataUrl: string, sourceVersionId: string) {
        const createdSource = await createSource({
            type: SourceType.OUTPUT_TABLE,
            title: name,
            description: description,
        })

        const sourceVersion = await createSourceVersion(createdSource.id, {
            dependencyUpdateMethod: 'model',
            name: 'Filtered version',
        })

        const url = await getSourceVersionContentUrl(createdSource.id, sourceVersion.id);
        const { saveData } = useDataService();
        await saveData(dataUrl, {
            filterModel: options.filterModel,
            sortModel: options.sortModel,
            columnModel: options.columnModel,
        }, url);
        await createPlainOutputTable(createdSource.id, sourceVersion.id, {
            filterModel: options.filterModel,
            sortModel: options.sortModel,
            columnModel: options.columnModel,
            dependencyUpdateMethod: 'model',
        });

        
        await createOutputTableDependancy(createdSource.id, sourceVersion.id, {
            sourceVersionId: sourceVersionId,
            dfName: null,
        });

        return createdSource
    }

    async function getKnowledgeSourceColumns(sourceId: string, versionId: string) {
        const { data } = await axios.get<Column[]>(`${config.public.baseUrl}/api/datasets/sources/${sourceId}/versions/${versionId}/columns`)
        return data;
    }

    async function updateTable(sourceId: string, versionId: string, answer?: string) {
        const { data } = await api.post<UpdateTableResponseDto>(`/${sourceId}/versions/${versionId}/update-data`, {
            answer
        });
        return data;
    }

    async function getSourceVersions(sourceId: string) {
        const { data } = await api.get<SourceVersionDto[]>(`/${sourceId}/versions`);
        return data;
    }

    async function getSourceVersionDetails<T>(sourceId: string, versionId: string) {
        const { data } = await api.get<T>(`/${sourceId}/versions/${versionId}/details`);
        return data;
    }

    return {
        getSourceVersionDetails,
        updateKnowledgeSourceVersionColumns,
        deleteKnowledgeSourceVersionColumn,
        getKnowledgeSourceColumns,
        listTags,
        saveModifiedDatasetAsSource,
        createSource,
        listSourceTags,
        createSourceTag,
        deleteSourceTag,
        updateSource,
        deleteSource,
        moveSource,
        listKnowledgeSources,
        createWebPage,
        getKnowledgeSource,
        searchKnowledgeSources,
        createFolder,
        deleteFolder,
        updateFolder,
        moveFolder,
        listSourceVersions,
        createSourceVersion,
        getSourceVersion,
        updateSourceVersion,
        deleteSourceVersion,
        listKnowledgeSourcesFlat,
        getSourcePermissions,
        createSourcePermission,
        deleteSourcePermission,
        getOutputTableAnalysisBreakdown,
        getOutputTableDataUrl,
        getOutputTableSampleData,
        getOutputTableDependancies,
        getSourceVersionContentUrl,
        processPdf,
        createPlainOutputTable,
        createOutputTableDependancy,
        createPdf,
        updateTable,
        getSourceVersions,
        getKnowledgeSourceVersionDetails
    }
}
